import { List } from "immutable";
import moment from "moment";
import { request } from "./platform";
import config from "Constants/api_config";
import logger from "./logger";

import {
  TEAM_ID_FIELD,
  PROJECT_ID_FIELD,
  ORGANIZATION_ID_FIELD,
  ENVIRONMENT_ID_FIELD
} from "Constants/constants";

export const getRouteUrl = route =>
  `${route.scheme}://${route.host}${route.path}`;

export const normalize = (array = [], idFieldName = "id") =>
  array.reduce((accumulator, currentObject) => {
    accumulator[currentObject[idFieldName]] = currentObject;
    return accumulator;
  }, {});

export const getEnvironmentParents = (environments, childId, parents = []) => {
  let environment = environments.find(env => env.id === childId);
  const parent = environments.find(env => env.id === environment.parent);
  if (parent.parent) {
    return parents.concat([
      getEnvironmentParents(environments, parent.id, parents)
    ]);
  }

  return parents.concat(parent);
};

export const immutableListToMap = (list, keyFieldName, valueFieldName) =>
  list.reduce((map, obj) => {
    const key = obj.get(keyFieldName);
    map[key] = obj.get(valueFieldName);

    return map;
  }, {});

export const getOrganizationDescriptionId = (
  getState,
  projectDescriptionId
) => {
  if (getState().project.size > 0) {
    const orgId = getState().project.getIn(
      ["orgByProjectId", projectDescriptionId],
      false
    );
    return orgId;
  }
};

export const getProjectDescriptionId = (getState, projectId) => {
  const me = getState().app.get("me", new Map());
  const project = me
    .get("projects", new List())
    .find(p => p.get("id") === projectId);

  if (!project) {
    return project;
  }

  return project.get(PROJECT_ID_FIELD);
};

export const getProjectId = (getState, projectDescriptionId) => {
  const me = getState().app.get("me", new Map());
  const project = me
    .get("projects", new List())
    .find(p => p.get(PROJECT_ID_FIELD) === projectDescriptionId);

  if (!project) {
    return projectDescriptionId;
  }

  return project.get("id");
};

export const getOrganizationId = (getState, organizationDescriptionId) => {
  const me = getState().app.get("me", new Map());
  const organization = me
    .get("organizations", new List())
    .find(p => p.get(ORGANIZATION_ID_FIELD) === organizationDescriptionId);

  if (!organization) {
    return organization;
  }

  return organization.get("id");
};

export const getTeamId = (getState, teamDescriptionId) => {
  const me = getState().app.get("me", new Map());
  const team = me
    .get("teams", new List())
    .find(p => p.get(TEAM_ID_FIELD) === teamDescriptionId);

  if (!team) {
    return team;
  }

  return team.get("id");
};

export const getEnvironmentId = (
  getState,
  organizationDescriptionId,
  projectDescriptionId,
  environmentDescriptionId
) => {
  const environment = getState().environment.getIn(
    [
      "data",
      organizationDescriptionId,
      projectDescriptionId,
      environmentDescriptionId
    ],
    new Map()
  );

  if (!environment) {
    return environment;
  }

  return environment.id;
};

export const getEnvironmentDescriptionId = (
  getState,
  organizationDescriptionId,
  projectDescriptionId,
  environmentId
) => {
  const environment = getState()
    .environment.getIn(
      ["data", organizationDescriptionId, projectDescriptionId],
      new List()
    )
    .find(e => e.id === environmentId);

  if (!environment) {
    return false;
  }

  return environment[ENVIRONMENT_ID_FIELD];
};

export const goToEnvironment = (
  pushFct,
  organizationDescriptionId,
  projectDescriptionId,
  environmentDescriptionId
) =>
  pushFct(
    `/${organizationDescriptionId}/${projectDescriptionId}/${environmentDescriptionId}`
  );

export const goToProject = (
  pushFct,
  organizationDescriptionId,
  projectDescriptionId
) => pushFct(`/${organizationDescriptionId}/${projectDescriptionId}`);

export const goToOrganization = (pushFct, organizationDescriptionId) =>
  pushFct(`/${organizationDescriptionId}`);

export const getEnvironmentGitCommand = (
  project,
  environment,
  marchineName,
  type
) => {
  if (!project || !project.repository || !environment) {
    return false;
  }

  if (marchineName === "magento") {
    marchineName = "magento-cloud";
  }

  // Escape an environment (Git branch) name as a shell argument. We do not
  // need to worry about double escaping, because Git branch names cannot
  // contain backslashes.
  function escapeNameAsShellArg(str) {
    return str.replace(/(["\$`' \(\)])/g, "\\$1");
  }

  if (type === "cli") {
    return `${marchineName} get ${project.id} -e ${escapeNameAsShellArg(
      environment.name
    )}`;
  }

  if (type === "cli-project") {
    return `${marchineName} get ${project.id}`;
  }
  // const first = project.title.replace(" ", "-");
  const title = project.title.replace(/[^\w\s]/gi, "").replace(/\s+/g, "-");
  if (type === "git-project") {
    return `git clone ${project.repository.url} ${title}`;
  }

  return `git clone --branch ${escapeNameAsShellArg(environment.name)} ${
    project.repository.url
  } ${title}`;
};

export const getGitRemoteCommand = (project, marchineName) => {
  if (!project || !project.repository) {
    return false;
  }

  if (marchineName === "magento") {
    marchineName = "magento-cloud";
  }

  return `git remote add ${marchineName} ${project.repository.url}`;
};

export const getOrganizationDescriptionIdFromProject = project => {
  if (!project || !project.owner_info) {
    return false;
  }

  if (project.owner_info.type === "user") {
    return project.owner_info.username;
  }
};

export const checkGoLive = project => {
  if (project) {
    if (
      project.subscription &&
      project.subscription.plan &&
      project.subscription.plan === "development"
    ) {
      if (project.data._links["#subscription_change"]) {
        return "development";
      } else {
        return "no-permission";
      }
    } else {
      if (
        project.subscription &&
        project.subscription.plan &&
        project.subscription.plan !== "development" &&
        !project.default_domain
      ) {
        if (project.data._links["#edit"]) {
          return "domain";
        } else {
          return "no-permission";
        }
      } else {
        return "live";
      }
    }
  }
  return false;
};

export const constructLegacyUrl = (url, project) => {
  const params = url.split("/").filter(param => param !== "");

  if (!project) {
    return "https://accounts.platform.sh/";
  }

  const projectUrl = `https://${project.region}/projects/${project.id}`;
  if (!params[2]) {
    return projectUrl;
  }
  const isProjectSettings = params[2] === "settings";
  if (isProjectSettings) {
    return `${projectUrl}/edit`;
  }

  const environmentName = params[2];
  const isEnvironmentSettings = params[3] === "settings";
  const environmentUrl = `${projectUrl}/environments/${environmentName}`;
  if (!isEnvironmentSettings) {
    return environmentUrl;
  } else {
    return `${environmentUrl}/edit`;
  }
};

export const redirectFromBeta = (url, project) => {
  const legacyUrl = constructLegacyUrl(url, project);
  if (legacyUrl) {
    window.location = legacyUrl;
  }
};

// If there's no address or login settings, then HTTP access is disabled.
export const httpStatusDisplay = environment => {
  if (
    environment &&
    environment.http_access &&
    environment.http_access.is_enabled
  ) {
    if (
      environment.http_access.addresses.length === 0 &&
      Object.entries(environment.http_access.basic_auth).length === 0
    ) {
      return false;
    } else {
      return true;
    }
  }
  return false;
};

export const getSubscription = async (project, component) => {
  // @todo: MOVE this to a new action creator in redux.
  const LOAD_SUBSCRIPTION_START = "app/projects/load_subscription_start";
  const LOAD_SUBSCRIPTION_SUCCESS = "app/projects/load_subscription_success";
  const LOAD_SUBSCRIPTION_FAILURE = "app/projects/load_subscription_failure";

  component.setState({ subscriptionStatus: LOAD_SUBSCRIPTION_START });
  const subscriptionId = project.getSubscriptionId();
  const subscriptionEndpoint = `${
    config.api_url
  }/v1/subscriptions/${subscriptionId}`;
  try {
    const newSubscription = (await request(subscriptionEndpoint, "GET")) || {};
    // eslint-disable-next-line react/no-did-update-set-state
    component.setState({
      subscriptionStatus: LOAD_SUBSCRIPTION_SUCCESS,
      subscriptionData: newSubscription
    });
  } catch (e) {
    this.setState({ subscriptionStatus: LOAD_SUBSCRIPTION_FAILURE });
    logger(e, {
      action: "getSubscriptionData",
      subscriptionId,
      subscriptionEndpoint
    });
  }
};

export const formatAsCurrency = (amount, currency) => {
  if (typeof currency === "undefined") {
    throw new Error("A currency must be defined");
  }
  const format = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    minimumFractionDigits: 2
  });
  return format.format(amount);
};

export const isPlainObject = obj => {
  return Object.prototype.toString.call(obj) === "[object Object]";
};

export const sortProjectList = (a, b, settings) => {
  if (settings.sortType === "owner" && a.owner_info && b.owner_info) {
    if (a.owner_info.username && b.owner_info.username) {
      if (settings.sortOrder === "descend") {
        if (a.owner_info.username > b.owner_info.username) return -1;
        if (a.owner_info.username < b.owner_info.username) return 1;
        return 0;
      }
      if (a.owner_info.username < b.owner_info.username) return -1;
      if (a.owner_info.username > b.owner_info.username) return 1;
      return 0;
    }
    return 0;
  }
  if (settings.sortType === "region" && a.region_label && b.region_label) {
    if (settings.sortOrder === "descend") {
      if (a.region_label.toLowerCase() > b.region_label.toLowerCase())
        return -1;
      if (a.region_label.toLowerCase() < b.region_label.toLowerCase()) return 1;
      return 0;
    }
    if (a.region_label.toLowerCase() < b.region_label.toLowerCase()) return -1;
    if (a.region_label.toLowerCase() > b.region_label.toLowerCase()) return 1;
    return 0;
  }
  if (settings.sortType === "id" && a.id && b.id) {
    if (settings.sortOrder === "descend") {
      if (a.id.toLowerCase() > b.id.toLowerCase()) return -1;
      if (a.id.toLowerCase() < b.id.toLowerCase()) return 1;
      return 0;
    }
    if (a.id.toLowerCase() < b.id.toLowerCase()) return -1;
    if (a.id.toLowerCase() > b.id.toLowerCase()) return 1;
    return 0;
  }
  if (settings.sortOrder === "descend" && a.title && b.title) {
    if (a.title.toLowerCase() > b.title.toLowerCase()) return -1;
    if (a.title.toLowerCase() < b.title.toLowerCase()) return 1;
    return 0;
  }
  if (a.title && b.title) {
    if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
    if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
  }
  return 0;
};

export const dateAsUTC = date => {
  return moment(date)
    .utc()
    .format();
};
