import { Map, fromJS } from "immutable";

import activityTypes from "Constants/activityTypes";
import {
  normalize,
  getProjectId,
  getOrganizationDescriptionId,
  getEnvironmentId,
  getEnvironmentDescriptionId
} from "Libs/utils";
import logger from "Libs/logger";

const LOAD_ACTIVITY_START = "app/activity/load_start";
const LOAD_ACTIVITY_SUCCESS = "app/activity/load_success";
const LOAD_ACTIVITY_FAILURE = "app/activity/load_failure";

const LOAD_ENVIRONMENT_ACTIVITY_START = "app/activity/environment/load_start";
const LOAD_ENVIRONMENT_ACTIVITY_SUCCESS =
  "app/activity/environment/load_success";
const LOAD_ENVIRONMENT_ACTIVITY_FAILURE =
  "app/activity/environment/load_failure";

const LOAD_PROJECT_ACTIVITY_START = "app/activity/project/load_start";
const LOAD_PROJECT_ACTIVITY_SUCCESS = "app/activity/project/load_success";
const LOAD_PROJECT_ACTIVITY_FAILURE = "app/activity/project/load_failure";

const UPDATE_PROJECT_ACTIVITY_START = "app/activity/project/update_start";
const UPDATE_PROJECT_ACTIVITY_SUCCESS = "app/activity/project/update_success";
const UPDATE_PROJECT_ACTIVITY_FAILURE = "app/activity/project/update_failure";

const UPDATE_ENVIRONMENT_ACTIVITY_START =
  "app/activity/environment/update_start";
const UPDATE_ENVIRONMENT_ACTIVITY_SUCCESS =
  "app/activity/environment/update_sucess";
const UPDATE_ENVIRONMENT_ACTIVITY_FAILURE =
  "app/activity/environment/update_failure";

export const loadActivitySuccess = activity => {
  return (dispatch, getState) => {
    dispatch({ type: LOAD_ACTIVITY_START });

    const projectDescriptionId = activity.project;
    const organizationDescriptionId = getOrganizationDescriptionId(
      getState,
      projectDescriptionId
    );

    try {
      activity.environments.forEach(environmentId => {
        dispatch({
          type: LOAD_ACTIVITY_SUCCESS,
          payload: activity,
          meta: {
            organizationDescriptionId,
            projectDescriptionId,
            environmentDescriptionId: getEnvironmentDescriptionId(
              getState,
              organizationDescriptionId,
              projectDescriptionId,
              environmentId
            )
          }
        });
      });
    } catch (error) {
      logger(error, {
        action: LOAD_ACTIVITY_FAILURE,
        meta: {
          organizationDescriptionId,
          projectDescriptionId
        }
      });
      dispatch({ type: LOAD_ACTIVITY_FAILURE, error: true, payload: error });
    }
  };
};

export const loadProjectActivities = (
  projectDescriptionId,
  organizationDescriptionId,
  type
) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOAD_PROJECT_ACTIVITY_START });

    try {
      const project = getState().project.getIn([
        "data",
        organizationDescriptionId,
        projectDescriptionId
      ]);

      const filterType =
        type && type !== "all_type" ? activityTypes[type].types : undefined;

      const activities = await project.getActivities(filterType);

      let hasMore = false;
      if (activities.length > 9) {
        hasMore = true;
        activities.pop();
      }

      dispatch({
        type: LOAD_PROJECT_ACTIVITY_SUCCESS,
        payload: {
          activities
        },
        meta: {
          hasMore,
          organizationDescriptionId,
          projectDescriptionId
        }
      });
    } catch (error) {
      logger(error, {
        action: LOAD_PROJECT_ACTIVITY_SUCCESS,
        meta: {
          organizationDescriptionId,
          projectDescriptionId
        }
      });
      dispatch({
        type: LOAD_PROJECT_ACTIVITY_FAILURE,
        error: true,
        payload: error
      });
    }
  };
};
export const loadEnvironmentActivities = (
  projectDescriptionId,
  environmentDescriptionId,
  organizationDescriptionId,
  type
) => {
  return async dispatch => {
    dispatch({ type: LOAD_ENVIRONMENT_ACTIVITY_START });

    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      const encodedEnvId = encodeURIComponent(environmentDescriptionId);

      const filterType =
        type && type !== "all_type" ? activityTypes[type].types : undefined;
      const activities = await client.getEnvironmentActivities(
        projectDescriptionId,
        encodedEnvId,
        filterType
      );

      let hasMore = false;
      if (activities.length > 9) {
        hasMore = true;
        activities.pop();
      }

      const activitiesForEnvironment = activities.map(activity => activity.id);

      dispatch({
        type: LOAD_ENVIRONMENT_ACTIVITY_SUCCESS,
        payload: {
          activities
        },
        meta: {
          hasMore,
          organizationDescriptionId,
          environmentDescriptionId,
          projectDescriptionId,
          environmentActivity: activitiesForEnvironment
        }
      });
    } catch (err) {
      logger(err, {
        action: "environment_load_activities",
        meta: {
          organizationDescriptionId,
          environmentDescriptionId,
          projectDescriptionId
        }
      });
      dispatch({
        type: LOAD_ENVIRONMENT_ACTIVITY_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};
export const loadMoreProjectActivities = (projectDescriptionId, type) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_PROJECT_ACTIVITY_START });

    const organizationDescriptionId = getOrganizationDescriptionId(
      getState,
      projectDescriptionId
    );
    const activitiesAlreadyLoaded = getState().activity || new Map();
    let activitiesAlreadyLoadedForProject = activitiesAlreadyLoaded.getIn(
      ["byProject", "data", organizationDescriptionId, projectDescriptionId],
      new Map()
    );
    let startsAt = false;

    if (type) {
      activitiesAlreadyLoadedForProject = activitiesAlreadyLoadedForProject.filter(
        activity =>
          activityTypes[type]
            ? activityTypes[type].types.indexOf(activity.type) !== -1
            : true
      );
    }

    // If we already have activities for this environment
    // We extract the older
    activitiesAlreadyLoadedForProject.valueSeq().forEach(activity => {
      if (
        !startsAt ||
        new Date(activity.created_at).getTime() < new Date(startsAt).getTime()
      )
        startsAt = activity.created_at;
    });

    try {
      const project = getState().project.getIn([
        "data",
        organizationDescriptionId,
        projectDescriptionId
      ]);
      const activities = await project.getActivities(type, startsAt);
      let hasMore = false;
      if (activities.length > 9) {
        hasMore = true;
        activities.pop();
      }

      // Ensure project level activity is loaded not just environment level activity.
      dispatch({
        type: UPDATE_PROJECT_ACTIVITY_SUCCESS,
        payload: {
          activities
        },
        meta: {
          hasMore,
          organizationDescriptionId,
          projectDescriptionId,
          type
        }
      });
    } catch (error) {
      logger(error, {
        action: UPDATE_PROJECT_ACTIVITY_FAILURE,
        meta: {
          organizationDescriptionId,
          projectDescriptionId,
          type
        }
      });
      dispatch({
        type: UPDATE_PROJECT_ACTIVITY_FAILURE,
        error: true,
        payload: error
      });
    }
  };
};

export const loadMoreEnvironmentActivities = (
  projectDescriptionId,
  environmentDescriptionId,
  type
) => {
  return async (dispatch, getState) => {
    const organizationDescriptionId = getOrganizationDescriptionId(
      getState,
      projectDescriptionId
    );

    const activitiesAlreadyLoaded =
      getState().activity.get("byEnvironment") || new Map();
    let activitiesAlreadyLoadedForProject = activitiesAlreadyLoaded.getIn(
      [
        "data",
        organizationDescriptionId,
        projectDescriptionId,
        environmentDescriptionId
      ],
      []
    );

    dispatch({ type: UPDATE_ENVIRONMENT_ACTIVITY_START });

    let startsAt = false;

    if (type) {
      activitiesAlreadyLoadedForProject = activitiesAlreadyLoadedForProject.filter(
        activity =>
          activityTypes[type]
            ? activityTypes[type].types.indexOf(activity.type) !== -1
            : true
      );
    }

    // If we already have activities for this environment
    // We extract the older
    activitiesAlreadyLoadedForProject.forEach(activity => {
      if (
        !startsAt ||
        new Date(activity.created_at).getTime() < new Date(startsAt).getTime()
      )
        startsAt = activity.created_at;
    });

    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      const activities = await client.getEnvironmentActivities(
        getProjectId(getState, projectDescriptionId),
        getEnvironmentId(
          getState,
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId
        ),
        type && type !== "all_type" ? activityTypes[type].types : undefined,
        startsAt
      );

      let hasMore = false;
      if (activities.length > 9) {
        hasMore = true;
        activities.pop();
      }

      dispatch({
        type: UPDATE_ENVIRONMENT_ACTIVITY_SUCCESS,
        payload: {
          activities
        },
        meta: {
          hasMore,
          organizationDescriptionId,
          environmentDescriptionId,
          projectDescriptionId,
          type
        }
      });
    } catch (err) {
      logger(err, {
        action: UPDATE_ENVIRONMENT_ACTIVITY_SUCCESS,
        meta: {
          organizationDescriptionId,
          environmentDescriptionId,
          projectDescriptionId,
          type
        }
      });
      dispatch({
        type: UPDATE_ENVIRONMENT_ACTIVITY_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};

export default function activityReducer(state = new Map(), action) {
  switch (action.type) {
    case LOAD_ACTIVITY_START:
      return state
        .setIn(["byProject", "loading"], true)
        .setIn(["byEnvironment", "loading"], true);
    case LOAD_ACTIVITY_SUCCESS:
      return state
        .setIn(
          [
            "byProject",
            "data",
            action.meta.organizationDescriptionId,
            action.meta.projectDescriptionId,
            action.payload.id
          ],
          fromJS(action.payload)
        )
        .setIn(
          [
            "byEnvironment",
            "data",
            action.meta.organizationDescriptionId,
            action.meta.projectDescriptionId,
            action.meta.environmentDescriptionId,
            action.payload.id
          ],
          fromJS(action.payload)
        );
    case LOAD_PROJECT_ACTIVITY_START:
      return state.setIn(["byProject", "loading"], true);
    case LOAD_PROJECT_ACTIVITY_SUCCESS:
      return state
        .setIn(["byProject", "loading"], false)
        .setIn(["byProject", "hasMore"], action.meta.hasMore)
        .setIn(
          [
            "byProject",
            "data",
            action.meta.organizationDescriptionId,
            action.meta.projectDescriptionId
          ],
          fromJS(normalize(action.payload.activities))
        );
    case UPDATE_PROJECT_ACTIVITY_SUCCESS:
      return state.setIn(
        [
          "byProject",
          "data",
          action.meta.organizationDescriptionId,
          action.meta.projectDescriptionId
        ],
        state
          .getIn(
            [
              "byProject",
              "data",
              action.meta.organizationDescriptionId,
              action.meta.projectDescriptionId
            ],
            new Map()
          )
          .concat(fromJS(normalize(action.payload.activities)))
      );
    case LOAD_ENVIRONMENT_ACTIVITY_START:
      return state.setIn(["byEnvironment", "loading"], true);
    case LOAD_ENVIRONMENT_ACTIVITY_SUCCESS:
      return state
        .setIn(["byEnvironment", "loading"], false)
        .setIn(["byProject", "hasMore"], action.meta.hasMore)
        .setIn(
          [
            "byEnvironment",
            "data",
            action.meta.organizationDescriptionId,
            action.meta.projectDescriptionId,
            action.meta.environmentDescriptionId
          ],
          fromJS(normalize(action.payload.activities))
        );
    case UPDATE_ENVIRONMENT_ACTIVITY_SUCCESS:
      return state.setIn(
        [
          "byEnvironment",
          "data",
          action.meta.organizationDescriptionId,
          action.meta.projectDescriptionId,
          action.meta.environmentDescriptionId
        ],
        state
          .getIn(
            [
              "byEnvironment",
              "data",
              action.meta.organizationDescriptionId,
              action.meta.projectDescriptionId,
              action.meta.environmentDescriptionId
            ],
            new Map()
          )
          .concat(fromJS(normalize(action.payload.activities)))
      );

    case LOAD_PROJECT_ACTIVITY_FAILURE:
    case LOAD_ENVIRONMENT_ACTIVITY_FAILURE:
    case UPDATE_PROJECT_ACTIVITY_FAILURE:
    case UPDATE_ENVIRONMENT_ACTIVITY_FAILURE:
      return state.set("error", action.payload);
    default:
      return state;
  }
}
