// Use the default JS client on production
let config = {
  api_url: process.env.API_PROXY_URL,
  client_id: process.env.API_CLIENT_ID,
  authentication_url: process.env.ACCOUNTS_URL,
  authorization: `${process.env.ACCOUNTS_URL}/oauth2/authorize`,
  logout_url: `${process.env.ACCOUNTS_URL}/user/logout`
};

if (process.env.NODE_ENV !== "production") {
  config = {
    base_url: "http://localhost:3000",
    client_id: "platform@ui.psh.dev",
    redirect_uri: "http://localhost:3000/",
    access_token: "alice",
    authorization: "https://accounts.internal.platform.sh/oauth2/authorize",
    api_url: "http://localhost:3000/api"
  };
}

export default config;
